import styled from "@emotion/styled"
import { Combobox } from "@ariakit/react"

import { SearchIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import { defaultSelectHeight } from "../constants"

const StyledComboboxContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 150;

  padding: ${tokens.spacing[1]};
  background: ${({ theme }) => theme.colorBackground};
`

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;

  top: 15px;
  left: ${tokens.spacing[3]};
`

const StyledCombobox = styled(Combobox)`
  width: 100%;
  height: ${defaultSelectHeight};

  background-color: ${({ theme }) => theme.colorBackgroundWidget};

  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};

  padding: ${tokens.spacing[2]} ${tokens.spacing[3]} ${tokens.spacing[2]} ${tokens.spacing[8]};

  font-size: ${tokens.typography.fontSize.body};
  line-height: ${tokens.typography.lineHeight};
  color: ${({ theme }) => theme.colorTextStrong};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    outline-offset: -2px;
  }
`

export function SelectCombobox({ comboboxProps, options }) {
  const handleOnCreate = event => {
    const shouldCallOnCreate = event.key === "Enter" && !options?.length && comboboxProps?.creatable

    if (shouldCallOnCreate) {
      comboboxProps?.onCreate?.(event?.target?.value)
    }
  }

  return (
    <StyledComboboxContainer>
      <StyledSearchIcon {...{ color: "colorTextWeakest" }} />

      <StyledCombobox
        {...{
          autoSelect: false,
          onKeyDown: handleOnCreate,
          disabled: comboboxProps?.disabled,
          placeholder: comboboxProps?.creatable ? localized("Search or create option") : localized("Search..."),
        }}
      />
    </StyledComboboxContainer>
  )
}
