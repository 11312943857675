import React, { useEffect, useMemo, useRef } from "react"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { VList } from "virtua"

import { ComboboxList, SelectGroup, SelectGroupLabel, useSelectContext } from "@ariakit/react"

import tokens from "@ninjaone/tokens"
import { MagnifyingGlass } from "@ninjaone/icons"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import { shouldIgnoreProps } from "../utils"
import { comboboxHeight, emptyStatePopoverHeight, emptyStatePopoverWidth, popoverMaxHeight } from "../constants"

import Body from "../../Typography/Body"

import SelectOption from "./SelectOption"
import SelectLoading from "./SelectLoading"

const StyledSelectGroupLabel = styled(SelectGroupLabel)`
  display: flex;
  align-items: center;

  text-transform: uppercase;

  line-height: ${tokens.typography.lineHeight};
  font-size: ${tokens.typography.fontSize.bodyXs};
  color: ${({ theme }) => theme.colorTextWeakest};

  padding: ${tokens.spacing[2]} ${tokens.spacing[3]} 0 ${tokens.spacing[3]};
`

const StyledSelectGroup = styled(SelectGroup, {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  ${({ theme, isComboboxVisible }) => {
    if (isComboboxVisible) {
      return `border-top: 1px solid ${theme.colorBorderWeak};`
    }

    return `
      &:not(:first-of-type) {
        border-top: 1px solid ${theme.colorBorderWeak};
      }
      `
  }}
`

const StyledEmptyState = styled("div", {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  width: ${({ sameWidth }) => (sameWidth ? "100%" : emptyStatePopoverWidth)};
  height: ${emptyStatePopoverHeight};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${tokens.spacing[1]};
`

function OptionsContainer({ children, options, selectedValue, virtualizedListRef, onScrollEnd }) {
  return options.length > 20 ? (
    <VList
      {...{
        ref: virtualizedListRef,
        onScrollEnd,
        style: {
          height: `calc(${popoverMaxHeight} - ${comboboxHeight} - 2px)`,
        },
      }}
    >
      {children}
    </VList>
  ) : (
    children
  )
}

function SelectOptions({ isMulti, options, selectedValue, virtualizedListRef, onScrollEnd }) {
  const selectOptions = useMemo(
    () => options.map(({ value, ...item }) => <SelectOption {...{ key: value, value, isMulti, ...item }} />),
    [isMulti, options],
  )

  return (
    <OptionsContainer {...{ options, selectedValue, virtualizedListRef, onScrollEnd }}>
      {selectOptions}
    </OptionsContainer>
  )
}

function SelectGroupOptions({ isComboboxVisible, isMulti, options, selectedValue, virtualizedListRef, onScrollEnd }) {
  const selectOptions = useMemo(
    () =>
      options.map(([group, groupOptions], index) => (
        <React.Fragment {...{ key: group }}>
          <StyledSelectGroup {...{ isComboboxVisible }}>
            {group && group !== "undefined" && <StyledSelectGroupLabel>{group}</StyledSelectGroupLabel>}

            <SelectOptionsList {...{ options: groupOptions, isMulti }} />
          </StyledSelectGroup>
        </React.Fragment>
      )),
    [isComboboxVisible, isMulti, options],
  )

  return (
    <OptionsContainer {...{ options, selectedValue, virtualizedListRef, onScrollEnd }}>
      {selectOptions}
    </OptionsContainer>
  )
}

export function SelectOptionsList({
  creatable,
  groupKey,
  isComboboxVisible,
  isMulti,
  loading,
  options,
  sameWidth,
  noOptionsText,
  onScrollEnd,
}) {
  const virtualizedListRef = useRef()

  const selectStore = useSelectContext()
  const selectedValue = selectStore.useState("value")

  const selectOpen = selectStore.useState("open")

  const parsedSelectedValue = Array.isArray(selectedValue) ? selectedValue[selectedValue.length - 1] : selectedValue
  const selectedOptionIndexRef = useRef(options.findIndex(option => option.value === parsedSelectedValue))

  useEffect(() => {
    const selectedOptionIndex = selectedOptionIndexRef.current

    if (selectOpen && selectedOptionIndex !== -1 && virtualizedListRef.current) {
      virtualizedListRef.current.scrollToIndex(selectedOptionIndex, {
        align: "center",
      })
    }
  }, [selectOpen])

  const commonOptionListProps = { isMulti, options, selectedValue, virtualizedListRef, onScrollEnd }
  if (loading) return <SelectLoading {...{ isMulti, sameWidth }} />

  return (
    <ComboboxList>
      {groupKey ? (
        <SelectGroupOptions {...{ ...commonOptionListProps, isComboboxVisible }} />
      ) : (
        <SelectOptions {...commonOptionListProps} />
      )}

      {!options.length && (
        <StyledEmptyState {...{ sameWidth }}>
          <MagnifyingGlass />

          <Body {...{ color: "colorTextWeak", type: "bodyXs", fontWeight: tokens.typography.fontWeight.medium }}>
            {noOptionsText || (creatable ? localized("No match found") : localized("No options found"))}
          </Body>

          {creatable && (
            <Body {...{ color: "colorTextWeak", type: "bodyXs", marginTop: tokens.spacing[1] }}>
              {localized("Press Enter to create option")}
            </Body>
          )}
        </StyledEmptyState>
      )}
    </ComboboxList>
  )
}
