import { useCallback, useEffect, useMemo, useState } from "react"

import styled from "@emotion/styled"
import { useSelectContext } from "@ariakit/react"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"

import { SINGLE_SELECT_DISPLAY_TYPE, arrowIconWidth, errorIconWidth } from "../constants"

import Tags from "../../Tags"
import Body from "../../Typography/Body"

const StyledSelectValue = styled.div`
  max-width: ${({ errorMessage }) => {
    return errorMessage ? `calc(100% - ${arrowIconWidth} - ${errorIconWidth})` : `calc(100% - ${arrowIconWidth})`
  }};
`

export function SelectValue({ disabled, isMulti, placeholderText, selectedValueDisplay }) {
  const selectStore = useSelectContext()

  const selectedValue = selectStore.useState("value")
  const options = selectStore.useState("items")

  const [selectedValueOption, setSelectedValueOption] = useState([])

  // Always work with an array to simplify logic
  const valuesArray = useMemo(() => (Array.isArray(selectedValue) ? selectedValue : [selectedValue]), [selectedValue])
  const getSelectedOption = useCallback(value => options.find(option => option.value === value), [options])

  useEffect(() => {
    if (!valuesArray.length) {
      setSelectedValueOption([])
    } else {
      setSelectedValueOption(prevState => {
        const newOptions = valuesArray.filter(option => !prevState.some(prevOption => prevOption?.value === option))

        if (newOptions.length >= 0) {
          const parsedNewOptions = newOptions.map(option => getSelectedOption(option))

          return [...prevState, ...parsedNewOptions]
        }

        return prevState
      })
    }
  }, [getSelectedOption, valuesArray])

  const labelsArray = selectedValueOption.reduce((acc, option) => {
    if (option?.labelText !== undefined) {
      acc.push(option.labelText)
    }

    return acc
  }, [])

  const renderCustomSelectedValueLabel = () => {
    const commonSelectedValueBodyProps = {
      as: "p",
      type: "body",
      color: disabled ? "colorTextDisabled" : "colorTextStrong",
    }

    // Handle case where no labels are found
    if (!labelsArray.length) {
      return (
        <Body {...{ color: disabled ? "colorTextDisabled" : "colorTextWeakest", type: "headingXs" }}>
          {placeholderText || localized("Select")}
        </Body>
      )
    }

    // Single select scenario
    if (!isMulti) {
      if (selectedValueDisplay === SINGLE_SELECT_DISPLAY_TYPE.ICON) {
        const selectedOption = getSelectedOption(selectedValue)

        return <Flex alignItems="center">{selectedOption.icon}</Flex>
      }

      return <Body {...commonSelectedValueBodyProps}>{labelsArray[labelsArray.length - 1]}</Body>
    }

    // Default multi-select scenario
    const parsedTagLabels = labelsArray.map((label, index) => ({
      label,
      id: `${index}-${label}-tag`,
      variant: disabled ? "disabled" : "default",
    }))

    return <Tags {...{ labels: parsedTagLabels, maxItemsDisplayed: 20, showRemainingItemsTooltip: false }} />
  }

  return <StyledSelectValue>{renderCustomSelectedValueLabel()}</StyledSelectValue>
}
